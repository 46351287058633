export default [

  {
    title: 'Bảng tin',
    route: 'dashboard',
    icon: 'LayoutIcon',
    resource: 'contact',
    action: 'read',
  },
  {
    header: 'Gửi tin',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Tự động hóa',
    route: 'automation',
    icon: 'CodesandboxIcon',
    resource: 'automation',
    action: 'read',
  },
  // {
  //   title: 'Chiến dịch',
  //   route: 'campaign',
  //   icon: 'CrosshairIcon',
  //   resource: 'campaign',
  //   action: 'read',
  // },
  {
    title: 'Tin nhắn',
    route: 'message',
    icon: 'SendIcon',
    resource: 'message',
    action: 'read',
  },
  {
    header: 'Quản lý khách hàng',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Nhóm liên hệ',
    route: 'contact-groups',
    icon: 'UsersIcon',
    resource: 'contact-group',
    action: 'read',
  },
  {
    title: 'Danh bạ',
    route: 'contacts',
    icon: 'UserIcon',
    resource: 'contact',
    action: 'read',
  },
  {
    header: 'Công cụ Zalo',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Tài khoản Zalo',
    route: 'zaloAccounts',
    icon: 'CpuIcon',
    resource: 'contact',
    action: 'read',
  },
  {
    title: 'Mẫu tin Zalo',
    route: 'zaloTemplates',
    icon: 'FileTextIcon',
    resource: 'contact',
    action: 'read',
  },


];
