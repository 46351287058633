export default [
  {
    header: 'Cài đặt',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Cài đặt chung',
    icon: 'SettingsIcon',
    route: 'setting',
    resource: 'all',
    action: 'read',
  },
];
